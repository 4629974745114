.nav_cursos {
    display: grid;
    grid-template-columns: 1fr 1fr;
    background: #D28282;
    border-top: 1px solid white;
}

.curso_btn {
    padding: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-size: 1.1rem;
    font-weight: 600;
    color: white;
    border-right: 1px white solid;
    transition: .3s ease-in-out;
}

.ultimo_curso_btn {
    border: none;
}

.curso_btn:hover {
    background-color: #f07079;
}

.curso_pintado {
    background-color: #f14c57 !important;
}

.cont_curso {
    margin-bottom: 5rem;
}

.cont_historia_presentacion {
    margin-bottom: 2rem;
}

.cont_curso {
    padding-left: 10%;
    padding-right: 10%;
}

.cont_info_curso {
    margin-top: 1rem;
    text-align: initial;
    padding-right: 1rem;
}

.cont_cursoInd {
    text-align: center;
    display: flex;
    flex-direction: row;
}

.titulo_curso {
    margin-bottom: 1rem;
    margin-top: 2rem;
    font-weight: 700;
    font-size: 2rem;
}

.subtitulo_curso {
    border: 1.5px solid black;
    border-radius: 15px;
    padding: .8rem;
    margin: .5rem;
    font-weight: 600;
    font-size: 1.2rem;
    transition: .5s ease;
}

.subtitulo_curso:hover {
    transform: scale(1.05);
    border: 1.5px solid #f14c57;
    color: #f14c57;
}

.cont_texto {
    margin-top: 1.5rem;
}

.programa {
    margin-top: 2rem;
    margin-bottom: 3.5rem;
}

.titulo_programa {
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
}

.cont_img_curso {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
}

.texto_taller {
    margin-top: 2rem;
    font-size: 1.2rem;
}

.foto_curso_100 {
    width: 100%;
    border-radius: 2rem;
}

.foto_curso_70 {
    width: 70%;
    border-radius: 2rem;
}

.foto_curso_140 {
    width: 140%;
    border-radius: 2rem;
}

.footer_mc {
    font-weight: 600;
}

.huella {
    content: url('https://api.iconify.design/mdi:paw.svg?height=24');
    vertical-align: -0.125em;
}


@media (max-width: 768px) {
    .nav_cursos {
        grid-template-columns: 1fr;
    }

    .cont_cursoInd {
        text-align: center;
        display: flex;
        flex-direction: column;
    }

    .curso_btn {
        padding: 1rem;
        border-right: none;
        border-bottom: 1px white solid;
    }

    .subtitulo_curso {
        border: 1.5px solid #f07079;
        color: #f07079;
    }

    .cont_img_curso {
        margin-top: 3rem;
    }
}