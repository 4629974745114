.section_form {
    margin-bottom: 3rem;
}

.titulo_form {
    font-size: 2.5rem;
    text-align: initial;
}

.grid_form {
    margin-top: 2.5rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.cont_img {
    display: flex;
    justify-content: center;
    align-items: center;
}

.img_form {
    width: 70%;
}

.form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 90%;
}

.input_form {
    width: 100%;
    border: none;
    outline: none;
}

.input_box {
    position: relative;
    width: 100%;
    margin-bottom: 1rem;
    padding: .6rem;
    border: rgba(0, 0, 0, 0.4) 1px solid;
}

.validado {
    border: #5AC055 solid 2px !important;
}

.invalidado {
    border: rgba(255, 59, 59, 0.623) solid 2px !important;
}

.invalidado::after {
    content: '';
    position: absolute;
    right: 12px;
    width: 24px;
    height: 24px;
    background: url('../icons/invalid.png');
    background-size: cover;
    z-index: 1000;
}

.validado::after {
    content: '';
    position: absolute;
    right: 12px;
    width: 24px;
    height: 24px;
    background: url('../icons/valid.png');
    background-size: cover;
    z-index: 1000;
}

form>input {
    margin-bottom: 1rem;
    padding-left: .5rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
}

form>label {
    text-align: initial;
}

textarea {
    padding-left: .5rem;
    resize: none;
    height: 20vh;
    background-color: #FDE9EA;
    border: none;
    padding-top: .5rem;
    padding-bottom: .5rem;
}

.cont_btn_form {
    width: 100%;
    height: auto;
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
}

.btn_enviar_form {
    background-color: #FF0000;
    color: white;
    border: none;
    padding: .5rem;
    padding-left: 3rem;
    padding-right: 3rem;
}

.pos_mail {
    margin-top: 1rem;
}

.pos_error {
    color: red;
}

.pos_success {
    color: green;
}

@media (max-width: 1100px) {
    .titulo_form {
        font-size: 2rem;
    }
}

@media (max-width: 900px) {
    .grid_form {
        grid-template-columns: 1fr;
    }

    .cont_img {
        margin-top: 2rem;
    }

    .form {
        display: flex;
        justify-content: center;
    }

    .cont_form {
        display: flex;
        justify-content: center;
    }

    .form {
        width: 90%;
    }

    .titulo_form {
        font-size: 1.5rem;
        text-align: center;
    }
}