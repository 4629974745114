@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap');

.main_cont {
    width: 100%;
}

.video-land {
    width: 100%;
    height: 80vh;
}

.section_deportes .video-land {
    height: 50vh;
}

.video-stream {
    width: 100% !important;
    height: 200vh !important;
}

.perrito_escuela {
    width: 75%;
}

.cont_text_escuela {
    text-align: initial;
    display: flex;
    justify-content: center;
    align-items: center;

    padding-left: 20%;
    font-size: 3rem;

    font-family: "Poppins", sans-serif;
    font-weight: 600;
    z-index: 1;
}

.cont_img_escuela {
    margin-top: -1rem;
    display: flex;
    justify-content: initial;
}


.titulo_section {
    width: 100%;
    background-color: #EE1F2D;
    color: white;
    padding-top: 2rem;
    padding-bottom: 2rem;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 2rem;
}

.cont_habilidades {
    font-family: "Poppins";
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    text-align: initial;
    padding-left: 8rem;
    padding-right: 8rem;
}

.cont_habilidades:hover .btn_leer_mas {
    background-color: #EE1F2D;
    color: white;
}

.cont_texto_hab div:last-child {
    margin-top: 1rem;
}

.titulo_habilidades {
    font-size: 2.2rem;
    font-weight: 700;
    margin-bottom: 1.6rem;
}

.cont_btn_hab {
    margin-top: 2rem;
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.btn_leer_mas {
    color: #EE1F2D;
    background-color: transparent;
    border-style: solid;
    border-color: #EE1F2D;
    padding: 1rem;
    padding-left: 3rem;
    padding-right: 3rem;
    border-radius: 3rem;

    transition: .3s ease-in-out;
}

.section_edu {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.row_cursos {
    width: 80%;
    margin-top: 2rem;

    display: flex;
    justify-content: center;
}

.cont_card {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-bottom: 2rem;

}

.card_cursos {
    padding-top: 3rem;
    padding-bottom: 3rem;
    border-radius: 2rem;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 8px 8px 16px rgba(165, 177, 198, 0.8), -8px -8px 16px rgba(255, 255, 255, 0.8);

    width: 90%;
    transition: .3s ease-in-out;
}

.card_cursos:hover {
    background-color: #FDE9EA;
}

.card_cursos:hover .btn_leer_mas {
    background-color: #EE1F2D;
    color: white;
}

.img_curso {
    width: 50%;
    border-radius: 50%;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.1);
    border-width: 2px;
}

.nombre_curso {
    font-size: 1.2rem;
    font-weight: 500;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.numeros {
    margin-top: -0.5rem;
}

.cont_numeros {
    height: 100%;

    padding-right: 5rem;
    padding-left: 5rem;

    display: grid;
    grid-template-columns: 1fr 1fr;
}

.cont_card_uno {
    grid-row: 1/3;
}

.cont_card_dos {
    margin-top: 1rem;
}

.img_num {
    width: 100%;
}

.cont-card-num {
    display: flex;
    justify-content: center;
    align-items: center;

}

.tarjeta-num:hover {
    transform: scale(1.05);
    background-color: #f070792a;
}

.tarjeta-num {
    width: 60%;
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-radius: 2rem;
    font-family: "Poppins";
    font-weight: 600;
    font-size: 1.2rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    margin-bottom: 1rem;

    box-shadow: 8px 8px 16px rgba(165, 177, 198, 0.8), -8px -8px 16px rgba(255, 255, 255, 0.8);
    transition: .4s ease-in-out;

}

.tarjeta-num .img_curso {
    width: 55%;
}

.num-card {
    font-size: 3rem;
    margin-top: .5rem;
    margin-bottom: .5rem;
}

@media only screen and (max-width: 1460px) {
    .cont_habilidades {
        padding-left: 3rem;
        padding-right: 3rem;
    }

    .video_habilidades {
        height: 70vh !important;
    }

    .cont_texto_hab {
        font-size: .9rem;
    }
}

@media only screen and (max-width: 1240px) {
    .tarjeta-num {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
}

@media only screen and (max-width: 1199px) {
    .cont_habilidades {
        padding-top: 4rem;
        padding-bottom: 2rem;
    }

    .row_cursos div:last-child {
        margin-top: 0;
    }

    .tarjeta-num {
        width: 90%;
        margin-right: 0;
        margin-bottom: 0;
    }

    .cont_numeros {
        padding-right: 0;
        padding-left: 0;
    }

    .cont_numeros {
        grid-template-columns: 1fr 1fr 1fr;
    }

    .cont_card_uno {
        grid-row: 1/2;
    }

    .cont_card_dos {
        margin-top: 0;
    }

}

@media only screen and (max-width: 1000px) {
    .cont_text_escuela {
        font-size: 2rem;
    }
}

@media only screen and (max-width: 850px) {

    .video-land {
        height: 35vh;
    }

    .section_deportes .video-land {
        height: 35vh;
    }
}

@media only screen and (max-width: 768px) {
    .cont_text_escuela {
        margin-top: 1rem;
        margin-bottom: 2rem;
        padding-left: 15%;
        padding-right: 15%;
        text-align: center;
    }

    .cont_img_escuela {
        display: flex;
        justify-content: center;
    }

    .row_cursos div:last-child {
        margin-top: 0;
    }

    .cont_grid {
        padding-top: 2rem !important;
        padding-bottom: 2rem !important;
    }
}

@media only screen and (max-width: 568px) {
    .cont_text_escuela {
        padding-left: 10%;
        padding-right: 10%;
    }

    .cont_numeros {
        grid-template-rows: 1fr 1fr 1fr;
        grid-template-columns: 1fr;
    }

    .tarjeta-num {
        width: 60%;
    }

    .cont-card-num {
        margin-bottom: 2rem;
    }
}