@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap');

.cont_contacto {
    /* padding-top: 8rem; */
    font-family: "Poppins", sans-serif;
    font-weight: 500;
}

.row_info {
    padding-top: 4rem;
    background-color: #fff;
}

.margin_left_5 {
    width: 80%;
    margin-left: 5rem;
}

.span_info {
    font-weight: 300;
    color: black;
    margin-left: 0.5rem;
}

.titulo_esperamos {
    text-align: initial;
    font-size: 2.2rem;
}

.img_info {
    display: flex;
    width: 100%;
    justify-content: 'center';
}

.img_info img {
    margin-right: 0.5rem;
}

.mapa {
    width: 80%;
    height: 70vh;
    margin-bottom: 3rem;
}

.info_consulta_col {
    display: flex;
    justify-content: center;
}

.info_consulta {
    width: 80%;
    padding-left: 5rem;
}

.subtitle_contact {
    text-align: initial;
    margin-top: 1rem;
    margin-bottom: 1rem;

}

.textarea_form {
    border: rgba(0, 0, 0, 0.4) 1px solid !important;
}

@media only screen and (max-width: 850px) {
    .info_info_consulta {
        width: 100%;
    }
}

@media only screen and (max-width: 768px) {
    .mapa {
        margin-top: 2rem;
        width: 100%;
    }
}

@media only screen and (max-width: 600px) {

    .info_info_consulta {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .titulo_esperamos {
        text-align: center;
    }

    .email_contacto {
        text-align: center;
    }
}