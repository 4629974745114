@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

nav{
    z-index: 12;
    width: 100%;
    background-color: #ea343c;
    text-align: initial;
    display: flex;
    flex-direction: row;
    position: relative;
    justify-content: space-between;
    align-items: center;
}

.navbar-cont{
    position: sticky;
    z-index: 10;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.img-nav{
    margin-left: 2rem;
    margin-top: 0.3rem;
    margin-bottom: 0.3rem;
    width: 7.5rem;
}

.ul-nav{
    width: 70%;
    color: white;
    font-family: "Poppins", sans;
    font-size: 0.8rem;
    list-style-type: none;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    padding: 0;
    margin: 0;
    margin-right: 2rem;
}

.ul-nav a{
    color: white;
}

.li_nav{
    margin-left: 2rem;
    cursor: pointer;
    transition: .3s ease-in-out;
}

.li_nav:hover{
    font-weight: 600;
}

.btn-tienda{
    background-color: #363435;
    color: white;
    transition: .4s ease-in-out;
}

.btn-tienda > i{
    margin-left: 0.5rem;
}

.btn-tienda:hover{
    background-color: #ffffff;
    color: rgb(0, 0, 0);
}

/* Menú Nav Responsive  */

.nav-menu-resp{
    position: absolute;
    display: none;
    z-index: 11;
    top: -21rem;
    width: 60%;

    transition: 0.5s ease;
}

.height-zero{
    height: 0;
}

.hamburguer-menu{
    display: none;
    cursor: pointer;
    margin-right: 2rem;

    width: 35px;
    height: 30px;
}

.line{
    width: 100%;
    height: 3px;
    background-color: white;

    transition: all .8s;
}

.change .line-1{
    transform: rotateZ(-405deg) translate(-8px, 6px)
}

.change .line-2{
    opacity: 0;
}

.change .line-3{
    transform: rotateZ(405deg)  translate(-8px, -6px)
}

/* Clases para Navbar Responsive */

@media only screen and (max-width: 1450px){
    .ul-nav{
        width: 80%;
    }
}

@media only screen and (max-width: 1150px){
    .ul-nav{
        width: 90%;
    }
}

@media only screen and (max-width: 850px) {

    nav{
        width: 100%;
    }

    .ul-nav{
        display: none;
    }

    .nav-menu-resp{
        display: block;
    }

    .hamburguer-menu{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .img-nav{
        width: 5rem;
    }
    
    
}



.nav-ul-resp{
    margin-top: 0.4rem;
    list-style-type: none;
    color: white;
    font-family: "Poppins", sans;
    padding-left: 0;
}

.nav-ul-resp > li{
    padding: 0.7rem;
    background-color: rgb(49, 49, 49);
    border-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: rgba(255, 255, 255, 0.3);
}

.nav-ul-resp > li:last-child{
    border-bottom: none;
}

.menu-active{
    top: 5.5rem;
}