.cont_modal{
    position: fixed;
    z-index: 50;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: #fde9ea;

    padding-top: 2rem;
    padding-bottom: 2rem;

    top: 50%;
    right: 50%;
    transform: translate(50%,-50%);
    border-radius: 2rem;
    width: 45%;
    transition: .5s ease-in-out;
}

.cont_input{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-top: 1rem;
    width: 70%;
}

#input{
    width: 100%;
    padding-left: 1rem;
    height: 2rem;
    border: 1px solid rgba(51, 51, 51, 0.3);
    outline: none;
}

.news{
    width: 50%;
}

.titulo_modal{
    margin-top: 1rem;
    font-weight: 600;
    font-size: 1.5rem;
}

.sub_modal{
    margin-top: 1rem;
    width: 60%;
}

.cont_btn_modal{
    margin-top: 2rem;
    width: 100%;
}

.btn_sus{
    background-color: red;
    color: white;
    border: none;
    padding: .7rem;
    padding-left: 2rem;
    padding-right: 2rem;
}

.asterisco{
    color: red;
}

.fa-times{
    position: absolute;
    top: 2rem;
    right: 2rem;
    color: rgb(82, 82, 82);
    font-size: 1.5rem;
}

.fa-times:hover{
    cursor: pointer;
}

.subir_modal{
    top: -50vh;
}

.pos_mail_modal{
    width: 100%;
}

@media (max-width: 690px){
    .cont_modal{
        width: 90%;
    }
}