@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');

.section_footer {
    background-color: #1d1d1b;
    color: white;
    font-family: "Poppins";
    font-weight: 600;

    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;

    padding-left: 3rem;
    padding-right: 3rem;
    padding-top: 4.2rem;
    padding-bottom: 1rem;
}

.titulo_footer {
    font-size: 1.5rem;
}

.img_redes {
    margin-bottom: 1rem;
    margin-top: 1rem;
    display: flex;
    justify-content: center;
}

.img_redes img {
    width: 3rem;
    margin-right: 1rem;
}

.footer_description {
    text-align: initial;
    margin-top: 1rem;
    font-weight: 300;
}

.footer_description div {
    font-size: .8rem;
}

.titulo_desc {
    font-weight: 500;
    font-size: 1.2rem !important;
    margin-bottom: .6rem;
}

.linea {
    margin-top: 4.2rem;
    width: 100%;
    height: 2px;
    background-color: rgba(255, 255, 255, 0.2);
}

.footer_grid {
    display: grid;
    flex-direction: row;
    grid-template-columns: repeat(3, 1fr)
}

.input_footer {
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    border: 1px solid rgba(51, 51, 51, 0.3);
    outline: none;
}

.info_list_footer {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
}

.li_footer {
    text-decoration: underline;
    font-size: 15px;
}

.logo_footer {
    width: 10rem;
}