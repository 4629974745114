.cont_row {
    margin-bottom: 3rem;
    padding-left: 10%;
    padding-right: 10%;
}

.end-message {
    font-weight: 600;
    font-size: 1.3rem;
}

.titulo {
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
}

.titulo_segmento {
    font-size: 1.2rem;
    margin-bottom: .5rem;
}

.bold {
    font-weight: 600;
}